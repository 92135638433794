<template>
  <VFlex
    class="item-addon mb-1"
    shrink
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <VLayout row>
      <VFlex v-if="!!title.length" shrink>
        <BaseEditable
          ref="selectedOnMountTarget"
          tag="span"
          :content="title"
          :mods="myMods.title"
          class="item-addon--title "
          @update="updateAddon('title', $event)"
        />
      </VFlex>
      <VFlex v-else-if="loggedIn" shrink>
        <div
          class="title-placeholder"
          @click="updateAddon('title', 'Addon Name')"
          >New Addon</div
        >
      </VFlex>

      <VFlex v-if="prices.length || title.length" shrink>
        <MenuAddonPrices
          :prices="prices"
          :is-dirty="
            addon.isNew || (myMods.prices && myMods.prices !== addon.prices)
          "
          @update="updateAddon('prices', $event)"
          @addPrice="updateAddon('prices', [...prices, 0])"
        />
      </VFlex>
      <BaseButtonIconMini
        v-if="isShowingEditUI"
        flat
        text-color="db-purple"
        md-icon="delete"
        xs
        @click="$emit('remove')"
      />
      <VFlex v-if="!addon.isLast" class="item-addon--separator" shrink>
        |
      </VFlex>
    </VLayout>
  </VFlex>
</template>

<script>
import { mapGetters } from 'vuex'
import deepCompareMods from '@utils/deep-compare-mods'

import selectedOnMountMixin from '@mixins/selected-on-mount'
import MenuAddonPrices from '@menu/MenuAddonPrices'
import GetsDirty from '@mixins/gets-dirty'

export default {
  name: 'MenuItemAddon',
  components: { MenuAddonPrices },
  mixins: [GetsDirty('ITEM_ADDON'), selectedOnMountMixin('addon.isNew')],
  props: {
    addon: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isHovering: false,
      original: this.addon,
    }
  },
  computed: {
    ...mapGetters('entities', ['getEntityMods']),
    ...mapGetters('addons', ['getAddonPrices']),
    ...mapGetters('editor', ['isShowingEditUI']),
    ...mapGetters('auth', ['loggedIn']),
    myMods() {
      return deepCompareMods(this.original, this.item)
    }, // myMods
    prices() {
      return this.myMods && this.myMods.prices
        ? this.myMods.prices
        : typeof this.addon.prices === 'string'
        ? JSON.parse(this.addon.prices)
        : this.addon.prices
    }, // prices
    title() {
      return typeof this.myMods.title !== 'undefined'
        ? this.myMods.title
        : this.addon.title
    }, // title
  }, // computed
  methods: {
    updateAddon(key, val) {
      this.$emit('updateAddon', { key, val })
    }, // updateAddon
  }, // methods
}
</script>

<style lang="scss">
@import '@design';

.item-addon {
  font-size: 0.75em;
  font-style: italic;
  font-weight: 500;
}
.item-addon--title,
.item-addon--prices {
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.item-addon--prices {
  margin-left: 0.5em;
}
.item-addon--separator {
  margin: 0 5px;
}
.menu-layout .menu-item--addons.is-dirty {
  &::after {
    display: none;
  }
}
.menu-layout .item-addon--title.is-dirty {
  &::after {
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 3px;
  }
}
</style>
